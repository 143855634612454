import React from "react";

const BoxArchivio = ({ items, anchor }) => {
  return (
    <>
      <section id={anchor} className="section section--grey-dark">
        <div className="box-archivio-wrap">
          {items.map((item, i) => (
            <div key={i} className="box-archivio">
              <div className="container-md">
                <div className="row">
                  <div className="col-12">
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Vai al sito dell'edizione 2022"
                      className="box-archivio__btn text-start d-flex justify-content-between align-items-center"
                    >
                      <span>
                        {item.title} <strong>{item.year}</strong>
                      </span>
                      <span className="box-archivio__arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default BoxArchivio;
